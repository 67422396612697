import React from "react";
import PolicyFile from "../../assets/documents/policy.pdf";

const Policy = () => {
    return (
        <div>
            <a href={PolicyFile} target="_blank">Church Policy</a>
        </div>
    );
}

export default Policy;