import React from 'react';
import edmund from '../../../assets/images/edd.jpeg';
import ordination1 from '../../../assets/images/ordinantion/1.jpg';
import ordination2 from '../../../assets/images/ordinantion/2.jpg';
import ordination3 from '../../../assets/images/ordinantion/3.jpg';
import ordination4 from '../../../assets/images/ordinantion/4.jpg';
import ordination5 from '../../../assets/images/ordinantion/5.jpg';
import ordination6 from '../../../assets/images/ordinantion/6.jpg';
import ordination7 from '../../../assets/images/ordinantion/7.jpg';
import ordination8 from '../../../assets/images/ordinantion/8.jpg';

const Founder = () => {
    return (
        <div>
            <div className="p-3" style={{ backgroundColor: '#DFDBCC' }}>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1 className="display-3"><b>Fundamental Truth of Jesus Christ Worldwide Ministry</b></h1>
                    </div>
                </div>
                <div className="row mt-5 p-3">
                    <div className="col-lg-3">
                        <img src={edmund} alt="Dr. Apostle Edmund P. Ibañez" className="img-fluid rounded p-2" />
                    </div>
                    <div className="col-lg-9">
                        <h2 className="fw-bold text-center text-lg-start">Dr. Apostle Edmund P. Ibañez</h2>
                        <p className="h4 text-center text-lg-start">Doctor of Philosophy in Theology (Ph.D. Theo)</p>
                        <p className="text-center text-lg-start">& Founding Chairman of Fundamental Truth of Jesus Christ Worldwide Ministry</p>
                        <h3 className="fw-bold text-center text-lg-start">Mandate of God 09 Jan 2018</h3>
                        <p className="text-center text-lg-start">7 “Write this letter to the angel of the church in Philadelphia. This is the message from the one who is holy and true, the one who has the key of David. What he opens, no one can close; and what he closes, no one can open: 8 “I know all the things you do, and I have opened a door for you that no one can close. You have little strength, yet you obeyed my word and did not deny me. Revelation 3:7-8"</p>
                        <p className="text-center text-lg-start">13 As soon as Jesus heard the news, he left in a boat to a remote area to be alone. But the crowds heard where he was headed and followed on foot from many towns. 14 Jesus saw the huge crowd as he stepped from the boat, and he had compassion on them and healed their sick. 15 That evening the disciples came to him and said, “This is a remote place, and it’s already getting late. Send the crowds away so they can go to the villages and buy food for themselves.” 16 But Jesus said, “That isn’t necessary—you feed them.” 17 “But we have only five loaves of bread and two fish!” they answered. 18 “Bring them here,” he said. 19 Then he told the people to sit down on the grass. Jesus took the five loaves and two fish, looked up toward heaven, and blessed them. Then, breaking the loaves into pieces, he gave the bread to the disciples, who distributed it to the people. 20 They all ate as much as they wanted, and afterward, the disciples picked up twelve baskets of leftovers. 21 About 5,000 men were fed that day, in addition to all the women and children! Matthew 14:13-21</p>
                        <p className="text-center text-lg-start">17 Jesus replied, “You are blessed, Simon son of John, because my Father in heaven has revealed this to you. You did not learn this from any human being. 18 Now I say to you that you are Peter (which means ‘rock’), and upon this rock I will build my church, and all the powers of hell will not conquer it. 19 And I will give you the keys of the Kingdom of Heaven. Whatever you forbid on earth will be forbidden in heaven, and whatever you permit on earth will be permitted in heaven.” Matthew 16:17-19</p>
                        <p className="text-center text-lg-start">10 So he took me in the Spirit to a great, high mountain, and he showed me the holy city, Jerusalem, descending out of heaven from God. 11 It shone with the glory of God and sparkled like a precious stone—like jasper as clear as crystal. 12 The city wall was broad and high, with twelve gates guarded by twelve angels. And the names of the twelve tribes of Israel were written on the gates. 13 There were three gates on each side—east, north, south, and west. 14 The wall of the city had twelve foundation stones, and on them were written the names of the twelve apostles of the Lamb. 15 The angel who talked to me held in his hand a gold measuring stick to measure the city, its gates, and its wall
                        . 16 When he measured it, he found it was a square, as wide as it was long. In fact, its length and width and height were each 1,400 miles. 17 Then he measured the walls and found them to be 216 feet thick (according to the human standard used by the angel). 18 The wall was made of jasper, and the city was pure gold, as clear as glass. 19 The wall of the city was built on foundation stones inlaid with twelve precious stones: the first was jasper, the second sapphire, the third agate, the fourth emerald, 20 the fifth onyx, the sixth carnelian, the seventh chrysolite, the eighth beryl, the ninth topaz, the tenth chrysoprase, the eleventh jacinth, the twelfth amethyst. 21 The twelve gates were made of pearls—each gate from a single pearl! And the main street was pure gold, as clear as glass. 22 I saw no temple in the city, for the Lord God Almighty and the Lamb are its temple. Revelation 21:10-22</p>
                    </div>
                </div>

                <div className="p-3" style={{ backgroundColor: '#DFDBCC' }}>
                    <div className="row">
                        <div className="col-md-15">
                            <div className="text-center">
                                <div className="row p-3 text-center">
                                    <h4 className="fw-bold">Ordination to an International Apostolic Bishop in the Philippines (Tagalog)</h4>
                                    <p className="fw-bold">& Graduation of Doctor of Philosophy in Theology (Ph.D. Theo)</p>
                                    <p>Graduated & Ordained in (2018) from the Northwestern Christian University Philippines</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination1} className="img-fluid rounded" alt="Image 1" />
                                </div>
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination2}  className="img-fluid rounded" alt="Image 2" />
                                </div>
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination3}  className="img-fluid rounded" alt="Image 3" />
                                </div>
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination4}  className="img-fluid rounded" alt="Image 4" />
                                </div>
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination5}  className="img-fluid rounded" alt="Image 5" />
                                </div>
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination6}  className="img-fluid rounded" alt="Image 6" />
                                </div>
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination7}  className="img-fluid rounded" alt="Image 7" />
                                </div>
                                <div className="col-sm-6 col-md-3 p-1">
                                    <img src={ordination8}  className="img-fluid rounded" alt="Image 8" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Founder;