import React from 'react';
import Introduction from '../../../components/contents/aboutUs/Introduction.js';
import StatementOfFaith from '../../../components/contents/aboutUs/StatementOfFaith.js';
import Founder from '../../../components/contents/aboutUs/Founder.js';

const About = () => {
    return (
        <div style={{ backgroundColor: '#DFDBCC' }}>
            <Introduction/>
            <StatementOfFaith/>
            <Founder/>
        </div>
    );
};

export default About;