import React from "react";

const StatementOfFaith = () => {
    return (
        <div>
            <div className="row p-3">
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ flexBasis: '100%', textAlign: 'center', marginBottom: '1em' }}>
                        <h2 style={{ fontWeight: 'bold' }}>Statement of Faith</h2>
                        <p>Fundamental Truth of Jesus Christ Statement of Faith</p>
                        <p>This Statement of Faith is intended simply as a basis for belief, fellowship and cooperation among us. The phraseology employed in this statement is not inspired, but the truth set forth is held to be essential to a truly Pentecostal ministry. No claim is made that it contains all biblical truth, only that it covers our need for these essential doctrines.</p>
                    </div>
                    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', textAlign: 'center', marginBottom: '1em' }}>
                        <h3 style={{ fontWeight: 'bold' }}>I. The Inspiration of the Scriptures</h3>
                        <p>We believe that the Scriptures, both the Old and New Testaments, are verbally inspired of God and are the revelation of God to man, the infallible authoritative rule of faith and conduct. Divine inspiration extends equality and fully to all parts of the original writings, ensuring their entire trustworthiness (2 Timothy 3:15-17, 2 Peter 1:21).</p>
                        <h3 style={{ fontWeight: 'bold' }}>II. The Eternal Godhead</h3>
                        <p>We believe in the unity of the one true and living God who is the eternal, self-existent One, and has revealed Himself as one being in three persons: Father, Son, and the Holy Spirit (Matthew 3:16-17; 28:19).</p>
                        <h4 style={{ fontWeight: 'bold' }}>a. God the Father</h4>
                        <p>We believe in God the Father, the first person of the triune Godhead, who exists eternally as the Creator of heaven and earth, the Giver of the Law, to whom all things will be subjected, so that He may be all in all (Genesis 1:1; Deuteronomy 6:4; 1 Corinthians 15:28).</p>
                        <h4 style={{ fontWeight: 'bold' }}>b. The Lord Jesus Christ</h4>
                        <p>We believe in the Lord Jesus Christ, the second person of the triune Godhead, who was and is the eternal Son of God; that He became incarnate by the Holy Spirit and was born of the virgin Mary. We believe in His sinless life, miraculous ministry, substitutionary atoning death, bodily resurrection, triumphant ascension, and abiding intercession (Isaiah 7:14; Hebrews 7:25-26; 1 Peter 2:22; Acts 1:9; 2:22; 10:38; 1 Corinthians 15:4; 2 Corinthians 5:21).</p>
                        <h4 style={{ fontWeight: 'bold' }}>c. The Holy Spirit</h4>
                        <p>We believe in the Holy Spirit, the third person of the triune Godhead, who proceeds from the Father and the Son, and is ever present and active in the work of convicting and regenerating the sinner, and sanctifying the believer into all truth (John 14:26; 16:8-11; 1 Peter 1:2).</p>
                    </div>
                    <div style={{ width: '100%', maxWidth: '1200px', margin: '0 auto', textAlign: 'center', marginBottom: '1em' }}>
                        <h3 style={{ fontWeight: 'bold' }}>III. The Fall of Man</h3>
                        <p>We believe that humankind was created good and upright. However, voluntary transgression resulted in their alienation from God, thereby incurring not only physical death but spiritual death, which is separation from God (Genesis 1:16-27; 2:17; 3:6; Romans 5:12-19).</p>
                        <h3 style={{ fontWeight: 'bold' }}>IV. Salvation</h3>
                        <p>We believe that the salvation of sinners is wholly of grace through faith in the shed blood of Jesus Christ; that it is necessary for the sinner to repent of his sins and receive Jesus Christ as personal Savior (Ephesians 2:8-9; Romans 10:9-10).</p>
                        <h3 style={{ fontWeight: 'bold' }}>V. The Church</h3>
                        <p>We believe in the universal church, the living spiritual body of which Christ is the head and all regenerated persons are members. We believe that the local church is a body of believers in Christ who are committed to worship, fellowship, and the proclamation of the Gospel (1 Corinthians 12:12-13; Ephesians 1:22-23).</p>
                        <h3 style={{ fontWeight: 'bold' }}>VI. The Second Coming</h3>
                        <p>We believe in the personal, imminent return of our Lord Jesus Christ for His church, and His subsequent return to establish His kingdom on earth (Acts 1:10-11; Revelation 22:12).</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatementOfFaith;