import React from 'react';
import Policy from '../../components/footer/Policy.js';
import Cookies from '../contents/Cookies.js';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div>
            <footer className="bg-dark text-light p-5">
                <div className="text-center py-4 py-md-0">
                    <div className="container mx-auto">
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Social Media</h3>
                                <p className="col-md-12">
                                    <a href="https://www.facebook.com/fundamentaltruthofchrist" target="_blank" rel="noopener noreferrer">
                                        <img src="https://seeklogo.com/images/F/facebook-icon-circle-logo-09F32F61FF-seeklogo.com.png" alt="Facebook Icon" width="40" height="40"/>
                                    </a>
                                    
                                </p>
                                <p className="col-md-12">
                                    <a href="https://www.youtube.com/@ApostleEdd" target="_blank" rel="noopener noreferrer">
                                        <img src="https://www.seekpng.com/png/full/3-30986_youtube-play-button-png-youtube-logo-round-png.png" alt="YouTube Icon" width="40" height="40"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center py-3">
                    <p>Copyright &copy; {currentYear} Fundamental Truth of Jesus Christ. All Rights Reserved.</p>
                </div>
            </footer>
            <Cookies/>
        </div>
    );
}



export default Footer;