import React, { useState, useEffect } from "react";
import Policy from "../footer/Policy.js";

const generateRandomCaptcha = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchaDisplay, setCaptchaDisplay] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setCaptchaDisplay(generateRandomCaptcha(6));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || !email || !message || !captcha) {
      setError("All fields are required.");
      return;
    }

    if (captcha !== captchaDisplay) {
      setError("CAPTCHA does not match.");
      // Generate a new CAPTCHA immediately
      setCaptchaDisplay(generateRandomCaptcha(6));
      setCaptcha(""); // Clear the input for CAPTCHA
      return;
    }

    // Create a mailto link
    const mailtoLink = `mailto:edmund-ibanez@hotmail.com?subject=Message from ${encodeURIComponent(
      name
    )}&body=${encodeURIComponent(message)}%0D%0AFrom: ${encodeURIComponent(
      email
    )}`;

    // Open the default email app
    window.location.href = mailtoLink;

    // Reset form
    setFormSubmitted(true);
    setError("");
    setName("");
    setEmail("");
    setMessage("");
    setCaptcha("");
    setCaptchaDisplay(generateRandomCaptcha(6));
  };

  const handleCaptchaClick = () => {
    const newCaptcha = generateRandomCaptcha(6);
    setCaptchaDisplay(newCaptcha);
  };

  return (
    <div style={{ backgroundColor: "#DFDBCC" }}>
      <div className="container">
        <div className="row p-3">
          <div className="col-md-6 col-sm-12">
            <h1 className="mb-4 text-center">Contact Us</h1>
            {formSubmitted && (
              <div className="alert alert-success">
                Thank you for your message!
              </div>
            )}
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className="form-group text-center">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  className="form-control text-center mx-auto"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="Enter your name"
                  style={{ maxWidth: "300px", width: "100%" }}
                />
              </div>
              <div className="form-group text-center">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  className="form-control text-center mx-auto"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Enter your email"
                  style={{ maxWidth: "300px", width: "100%" }}
                />
              </div>
              <div className="form-group text-center">
                <label htmlFor="message">Message:</label>
                <textarea
                  className="form-control text-center mx-auto"
                  id="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  placeholder="Enter your message"
                  style={{ maxWidth: "300px", width: "100%", height: "100px" }}
                />
              </div>
              <div className="form-group pb-3 text-center">
                <label htmlFor="captcha">CAPTCHA (click to regenerate):</label>
                <div
                  id="captcha"
                  className="captcha-display"
                  onClick={handleCaptchaClick}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    userSelect: "none",
                    textAlign: "center",
                  }}
                >
                  {captchaDisplay}
                </div>
                <input
                  type="text"
                  className="form-control text-center mx-auto"
                  value={captcha}
                  onChange={(e) => setCaptcha(e.target.value)}
                  required
                  placeholder="Enter CAPTCHA"
                  style={{ maxWidth: "300px", width: "100%" }}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ maxWidth: "300px", width: "100%" }}
              >
                Send Message
              </button>
            </form>
          </div>
          <div className="col-md-6 col-sm-12 pt-5">
            <h2 className="text-center">Intl. Educational Ministry</h2>
            Stichting Info Policy Plan Fundamental Truth of Christ
            <br />
            Koegelwieck 29, 2134 XX Hoofddorp
            <br />
            Website:{" "}
            <a
              href="http://www.fundamentaltruthofchrist.nl"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.fundamentaltruthofchrist.nl
            </a>
            <br />
            KVK: 70986665
            <br />
            Bank: NL34ABNA0819274267
            <br />
            <Policy /> {/* The Policy File */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
