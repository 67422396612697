import React, { useState } from "react";
import Introduction from "../charity/Introduction";
import Lucban2024 from "../charity/Lucban2024/Lucban2024";
import Pandemic2020 from "../charity/Pandemic2020/Pandemic2020";
import Buhi2020 from "../charity/Buhi2020/Buhi2020";

const Charity = () => {
    const [selectedSection, setSelectedSection] = useState('introduction');

    const renderContent = () => {
        switch (selectedSection) {
            case 'lucban':
                return <Lucban2024 />;
            case 'pandemic':
                return <Pandemic2020 />;
            case 'buhi':
                return <Buhi2020 />;
            default:
                return <Introduction />;
        }
    };

    return (
        <div className="p-3" style={{ backgroundColor: '#DFDBCC' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                <button onClick={() => setSelectedSection('lucban')}>Lucban 2024 - Read More</button>
                <button onClick={() => setSelectedSection('pandemic')}>Pandemic 2020 - Read More</button>
                <button onClick={() => setSelectedSection('buhi')}>Buhi 2020 - Read More</button>
            </div>
            {selectedSection !== 'introduction' && (
                <button onClick={() => setSelectedSection('introduction')} style={{ marginBottom: '20px' }}>
                    Back to Introduction
                </button>
            )}
            {renderContent()}
        </div>
    );
}

export default Charity;