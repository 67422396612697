import React, { useState } from 'react';
import logo from '../../assets/images/logos/logo.png';
import About from '../../components/contents/aboutUs/About.js';
import Homepage from '../../components/contents/Homepage.js';
import Charity from '../../components/contents/charity/Charity.js';
import Education from '../../components/contents/education/Education.js';
import Contact from '../contents/Contact.js';

const Navbar = () => {
    const [activeLink, setActiveLink] = useState('home');

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    const loadPage = () => {
        switch (activeLink) {
            case 'home':
                return <Homepage />;
            case 'about':
                return <About />;
            case 'charity':
                return <Charity />;
            case 'education':
                return <Education />;
            case 'contact':
                return <Contact />;
            default:
                return <Homepage />;
        }
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light shadows-sm">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <div className="mx-auto text-center">
                        <a className="navbar-brand" href="#" onClick={() => handleLinkClick('home')}>
                            <img src={logo} alt="Logo" width="30" height="45" className="d-inline-block" />
                            &nbsp;Fundamental Truth of Jesus Christ
                        </a>
                    </div>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto me-5">
                            <li className="nav-item ms-3">
                                <a className={`nav-link ${activeLink === 'home' ? 'active' : ''}`} onClick={() => handleLinkClick('home')} href="#" style={{ fontWeight: activeLink === 'home' ? 'bold' : 'normal' }}>
                                    Home
                                </a>
                            </li>
                            <li className="nav-item ms-3">
                                <a className={`nav-link ${activeLink === 'about' ? 'active' : ''}`} onClick={() => handleLinkClick('about')} href="#" style={{ fontWeight: activeLink === 'about' ? 'bold' : 'normal' }}>
                                    About Us
                                </a>
                            </li>
                            <li className="nav-item ms-3">
                                <a className={`nav-link ${activeLink === 'charity' ? 'active' : ''}`} onClick={() => handleLinkClick('charity')} href="#" style={{ fontWeight: activeLink === 'charity' ? 'bold' : 'normal' }}>
                                    Charity Program
                                </a>
                            </li>
                            <li className="nav-item ms-3">
                                <a className={`nav-link ${activeLink === 'education' ? 'active' : ''}`} onClick={() => handleLinkClick('education')} href="#" style={{ fontWeight: activeLink === 'education' ? 'bold' : 'normal' }}>
                                    Education
                                </a>
                            </li>
                            <li className="nav-item ms-3">
                                <a className={`nav-link ${activeLink === 'contact' ? 'active' : ''}`} onClick={() => handleLinkClick('contact')} href="#" style={{ fontWeight: activeLink === 'contact' ? 'bold' : 'normal' }}>
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="content">
                {loadPage()} {/* Render the selected content here */}
            </div>
        </div>
    );
}

export default Navbar;