import React from "react";

const Introduction = () => {
    return (
        <div>
            <div className="row p-3">
                <h3><b>The Educational Ministry</b></h3> is a non-sectarian, full gospel organization with a mission to bring people closer to the living God. Named Stichting Truth of Christ, this foundation is committed to fostering a Christ-centered community that embraces worship, fellowship, discipleship, and evangelism.
                The ministry’s vision is to cultivate an environment where Christ’s disciples acquire wisdom, knowledge, and critical thinking skills, grounded in a biblical worldview. This vision is demonstrated through a lifestyle of Christian discipline, character, leadership, and service.
                Its mission is to provide a high-quality, Christ-centered education that prepares disciples for leadership and service, both within the community and in the wider world. In this supportive environment, disciples learn to embody the values of their faith, becoming equipped to serve others and uphold Christian principles in all aspects of life.
                The purpose of the foundation is clear: to bring people into a meaningful relationship with God through the proclamation of His Word and to foster communion with one another in Christ.
                To achieve this, the ministry holds weekly meetings where God’s Word is central, offers Bible study and assistance, and organizes various evangelistic activities. In every endeavor, the foundation seeks to fulfill its commitment to spread God’s message and nurture faith in Jesus Christ.
                The Board of the foundation consists of a chairman, a secretary, and a treasurer, who work diligently to oversee the ministry’s objectives. All board members serve without remuneration, motivated solely by their dedication to the ministry’s cause.
                Through this structured and devoted leadership, the Educational Ministry continues to realize its purpose, encouraging others to experience communion with God and with each other, striving to make a lasting impact on the community and beyond.
            </div>
        </div>
    );
}

export default Introduction;