import React from 'react';
import book1 from './books/1.png';
import book2 from './books/2.png';
import book3 from './books/3.png';
import book4 from './books/4.png';
import book5 from './books/5.png';
import book6 from './books/6.png';
import book7 from './books/7.png';
import book8 from './books/8.png';

const Education = () => {
    return (
        <div>
          <div className="p-3">
            <div className="row mt-5 text-center">
              <div className="col">
                <h1 className="font-weight-bold">The Ministry Books</h1>
                <h4 className="font-italic text-muted">
                  Here you will find the books that the Ministry Fundamental Truth of Christ has experienced, obtained, and created by guidance of the Holy Spirit.
                </h4>
              </div>
            </div>
            <div className="row mt-5">
              {/* List of book cards */}
              {[{src: book1, title: "Step by Step 101: Level Up Discipleship Training", text: "It is not an easy road..."},
                {src: book2, title: "FTC Ministry Operational Manual", text: "The Ministry Operational Manual..."},
                {src: book3, title: "Step by Step 101: Level Up Pastoring", text: "It’s time for you to learn..."},
                {src: book4, title: "How to: Bible Study Cell Group Guideline", text: "The Guideline on how to do a BSCG..."},
                {src: book5, title: "Step by Step 101: The Basic Foundation of a Christian Life", text: "A perfect new learning..."},
                {src: book6, title: "Step by Step 101: Level Up Mentoring", text: "In this book LEVEL UP Mentoring..."},
                {src: book7, title: "Bachelor in Theology of Arts Book I", text: "A much deeper learning in the Spiritual Life First Part."},
                {src: book8, title: "Bachelor in Theology of Arts Book II", text: "A much deeper learning in the Spiritual Life Last Part."}
              ].map((book, index) => (
                <div className="col-md-3 mb-3" key={index}>
                  <div className="card h-100">
                    <img 
                      src={book.src} 
                      className="card-img-top" 
                      alt={`Book ${index + 1}`} 
                      loading="lazy" // Lazy loading
                    />
                    <div className="card-body">
                      <h5 className="card-title">{book.title}</h5>
                      <p className="card-text">{book.text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
    );
}

export default Education;