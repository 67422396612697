import React from "react";
import image1 from './lucban2024images/1.JPG';
import image2 from './lucban2024images/2.JPG';
import image3 from './lucban2024images/3.JPG';
import image4 from './lucban2024images/4.JPG';
import image5 from './lucban2024images/5.JPG';
import image6 from './lucban2024images/6.JPG';
import image7 from './lucban2024images/7.JPG';
import image8 from './lucban2024images/8.JPG';
import image9 from './lucban2024images/9.JPG';
import image10 from './lucban2024images/10.JPG';
import image11 from './lucban2024images/11.JPG';
import image12 from './lucban2024images/12.JPG';
import image13 from './lucban2024images/13.JPG';
import image14 from './lucban2024images/14.JPG';
import image15 from './lucban2024images/15.JPG';
import image16 from './lucban2024images/16.JPG';
import image17 from './lucban2024images/17.JPG';
import image18 from './lucban2024images/18.JPG';
import image19 from './lucban2024images/19.JPG';
import image21 from './lucban2024images/21.JPG';
import image22 from './lucban2024images/22.JPG';

const images = [
    image1, image2, image3, image4, image5, image6, image7, image8, image9, image10,
    image11, image12, image13, image14, image15, image16, image17, image18, image19, 
    image21, image22
];

const Lucban2024 = () => {
    return (
        <div className="p-3">
            <div className="row text-center">
                <div className="col">
                    <h1><b>Lucban 2024 Gift Giving 2024</b></h1>
                    <p className="text-justify">
                        "The Lucban Gift Giving 2024 program is all about helping those who don't have enough food, especially in poor areas of third-world countries. We give out big sacks of rice to these communities. With the help of kind donors and hardworking volunteers, we make sure that families who struggle to find enough to eat get this essential staple.
                        By providing rice sacks, we're offering more than just food – we're giving hope to people facing hunger. In Lucban, we believe that everyone deserves access to enough food to live a healthy life. Through this initiative, we're working to make that belief a reality.
                        Together, we can make a big difference in the lives of those in need. With each rice sack we deliver, we're helping families build a brighter future for themselves."
                    </p>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {images.map((image, index) => (
                    <div className="col" key={index}>
                        <div className="card h-100">
                            <img src={image} className="card-img-top img-fluid rounded-3" alt={`Lucban2024 Gift Giving Image ${index + 1}`} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Lucban2024;
