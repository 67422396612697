import React from "react";
// Buhi
import image1 from './Buhi2020images/3.jpeg';
import image2 from './Buhi2020images/8.jpeg';
import image3 from './Buhi2020images/10.jpeg';
import image4 from './Buhi2020images/5.jpeg';
// Mayon
import mayon1 from './Buhi2020images/11.jpeg';
import mayon2 from './Buhi2020images/12.jpeg';
// Christmas Gift
import gift1 from './Buhi2020images/1.jpeg';
import gift2 from './Buhi2020images/2.jpeg';

const Buhi = () => {
    return (
        <div>
            {/* <!-- Christmas Giving in Buhi 2020 --> */}
            <div class="p-3">
            <div class="row text-center">
                <div class="col">
                <h1>
                    <b>Christmas Giving in Buhi 2020</b>
                </h1>
                </div>
            </div>
            <div class="row text-center">
                <div class="col">
                <h2>Typhoons, Covid-19 and Bicol Region</h2>
                <p>Nehemiah 8:10 Nehemiah said,“ Go and enjoy taste food and sweet drinks, and send some to those who have nothing prepared. This day is holy to our Lord. Do not grieve, for the joy of the Lord is your strength“.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                <img src={image1} alt="Image" class="img-fluid rounded"/>
                </div>
                <div class="col-md-3">
                <img src={image2}  alt="Image" class="img-fluid rounded"/>
                </div>
                <div class="col-md-3">
                <img src={image3}  alt="Image" class="img-fluid rounded"/>
                </div>
                <div class="col-md-3">
                <img src={image4}  alt="Image" class="img-fluid rounded"/>
                </div>
            </div>
            <div class="row p-3">
                <h3>
                <b>The Year 2020</b>
                </h3>
                <div class="col-md-4">
                <img src={mayon1}  alt="Image 1" class="img-fluid rounded"/>
                </div>
                <div class="col-md-4">
                <img src={mayon2}  alt="Image 2" class="img-fluid rounded"/>
                </div>
                <div class="col-md-4">
                <p>was the time to face a natural disaster. The lava of Mt Mayon Volcano entered the roads of Albay Province and making extreme damages to the houses of the residents living around the volcano. The town of Buhi is just behind the mountain of Mayon Volcano, this Town was also had extreme damages like their occupations were extremely affected by the flood. People in this village have their source of living from the plants and the common occupation is fishing in the lake Buhi. Because of the very strong typhoon Quinta, Rolly and Ullyses hit this last quarter of the year the town people were homeless, helpless, and hungry. </p>
                </div>
            </div>
            <div class="p-3">
                <h3>
                <b>Glory to God!</b>
                </h3>
                <div class="row p-3">
                <div class="col-md-6">
                    <img src={gift1} alt="Image 1" class="img-fluid rounded"/>
                </div>
                <div class="col-md-6">
                    <h2>Christmas Gifts in Buhi</h2>
                    <p> The FTC Ministry is now 2 years in serving God. As an ambassadors of Christ we are representing how God is good in everyone of us. This year 2020 the event of we care we sharing Christmas Gifts in Buhi, Camarines Sur. This is the 4th times we did sharing our helping hand, Glory to God! Even we are a new church we are blessed to respond to the mandate of God in this ministry to contribute in feeding the needy not only in spiritual but even in physical. This season is the end year of 2020, we are already in 2 years now doing this charity works. Our desire is to help the needy in the emergency victims of calamities and even in a seasonal event to ease some heaviness in their heart and make them smile. FTC Worldwide Ministry is thanking God for the opportunity intrusted to this church to give relief goods and cooked food packs, share love and joy to our brothers and sisters after the storm from the victim of the successive typhoons hit the Philippines especially to the region 5 the Bicolandia Province. We don’t dwell on how badly they were affected by the typhoon, but FTC would like to share the goodness and faithfulness of God. We are forever grateful </p>
                </div>
                </div>
                <div class="row">
                <div class="col-md-6">
                    <img src={gift2} alt="Image 2" class="img-fluid rounded"/>
                </div>
                <div class="col-md-6">
                    <h2>Joy in our Hearts</h2>
                    <p>for the wisdom, favor, protection, guidance, provision and joy in our hearts to distribute the giving of gifts this Christmas. From the ‘we care we share foundation’ at exactly on Christmas day celebration is the distribution of the gifts. Thank you Lord Jesus it’s the celebration of your birthday, the first coming of our Lord and Savior in this world. We are happy to see the people received the gifts with the smile in their face saying “Merry Christmas and thank you very much FTC Ministry”! Thank you very much Jesus for you are truly the God of more than enough, we prayed for 50 families and you exceeded what we prayed for, the blessings we were still able to give gifts packed of ready to eat food to those homeless street kids. FTC wants to thank everyone who gave money donations, we are blessed in the life of the kind hearted sponsors from brethren in FTC Germany and FTC Hongkong. Thank you for the generosity hearts declaring a hundredfold return to all of you in Jesus name. We thanking the contact person in Buhi for facilitating the FTC charity project and for the victorious distribution of the relief goods. We give back the Glory to God for HIS faithfulness to supply more than we need. God bless!</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Buhi;