import React from 'react';
import bird from '../../assets/images/homepage/bird.jpg';
import index1 from '../../assets/images/homepage/index1.jpg';
import index2 from '../../assets/images/homepage/index2.jpg';
import index3 from '../../assets/images/homepage/index3.jpg';
import index4 from '../../assets/images/homepage/index4.jpg';

const Homepage = () => {
    return (
        <div>
            <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                    <img src={bird} class="d-block w-100" alt="Eagle"/>
                </div>
            </div>
            </div>
                <div class="row m-3">
                    <div class="col-md-3 p-2">
                    <div class="card h-100">
                        <img src={index1} class="card-img-top" alt="..."/>
                        <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Revelation 1: 8</h5>
                        <p class="card-text">I am Alpha and Omega, the beginning and the ending, saith the Lord, which is, and which was, and which is to come, the Almighty.</p>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3 p-2">
                    <div class="card h-100">
                        <img src={index2}  class="card-img-top" alt="..."/>
                        <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Revelation 2: 3 (NIV) </h5>
                        <p class="card-text">And you have endured difficulties and showed endurance. You have made efforts for My Name and you have not grown tired.</p>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3 p-2">
                    <div class="card h-100">
                        <img src={index3}  class="card-img-top" alt="..."/>
                        <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Revelation 1:17 (NIV) </h5>
                        <p class="card-text">And when I saw Him, I fell at His feet as dead, and He put His right hand on me and said to me, Fear not, I am the first and the last,</p>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-3 p-2">
                    <div class="card h-100">
                        <img src={index4}  class="card-img-top" alt="..."/>
                        <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Revelation 2:10 (NIV) </h5>
                        <p class="card-text">Do not be afraid of what you will suffer. Behold, the devil will cast some of you into prison that you may be tempted.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homepage;