import './App.css';
import Navbar from './components/header/Navbar';
import Footer from './components/footer/Footer';
// import Homepage from './components/contents/Homepage';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Footer/>
    </div>
  );
}

export default App;
