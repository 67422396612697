import React from "react";

const Introduction = () => {
    return (
        <div>
            <div class="p-3">
                <div class="row mt-3 p-3">
                    <h2 class="text-center">
                        <b>Our Charity Programs</b>
                    </h2>
                    <p>3 John 1:2 "Dear friend, I pray that you may enjoy good health and that all may go well with you, even as your soul is getting along well."</p>
                    <p>We all know very well that Christian life is a matter of faith or strong belief. So, to build up those strong beliefs and rhythms, we should become a part of different activities to grow in that faith. We should find the satisfaction of our soul in Christ alone. We need to understand that our spiritual growth depends on both God and us. To assist you in getting these concepts, we arrange multiple meetings where we discuss the plans and goals of the year for evangelism mission achievement. To bring people closer to Christ Jesus is the motivation behind these events.</p>
                    <p>We continue doing our evangelism every season through FTC church Amsterdam events. We use to organize a regular Sunday worship session to hear the message of the Lord and for that, we would like to welcome you all. There’s a midweek prayer session in FTC church or online and a session of “bible study cell grouping” in a week. There are also Weekly sessions of bible school taken by the pastors of FTC church and praying sessions for the therapy of sick people by home visitation service.</p>
                    <p>We convey the message of God for the salvation of many souls. In the Spring season, we have an outdoor evangelism mission session to reach out wider as we invite people to join us in our tours inside or outside the Netherland.</p>
                    <p>In the summer season, we use to invite people to our outdoor events like sports festivals, summer outing fellowship because we believe that it's a great way to show our passion for this Holy mission. In the autumn season, we arrange different indoor activities (church retreat) and it’s open for the people who want to show their affection towards God. It's a part of our evangelism to invite people for celebrating Christmas fellowshipping with us in the winter season. We organize this special event for all of you. Our evangelism via house visitations is for sharing the good news of God, showing the healing miracle of Jesus by praying for the sick, giving spiritual advice, encouraging people, and sharing salvation.</p>
                    <p>This is our pure intention behind organizing these holy events in our church every day. These are the few efforts from our side for the needy people in times of calamities. 2 Corinthians 9:7 "God loves a cheerful giver." We care we share for you!</p>
                </div>
            </div>
            <div class="p-3">
                <div class="row">
                    <div class="text-center">
                        <h2 class="mb-4">A Helping Hand and Spreading Joy</h2>
                        <h4 class="mb-4">The Supervision of FTC</h4>
                        <p class="text-justify">Joyfulness is something that neither comes from a huge bungalow nor a luxury car, joyfulness is something that belongs to help the creations of God. The best form of helping others is termed as a charity. Charity belongs to blessings and blessings belongs to helping the needy. The bond of these three makes the character of a person very strong and humble. We are living in a world full of disasters. Some are natural and some are human-created. It's our basic need as well as we taught how to tackle these situations. Whenever some natural disaster happens, our beloved things or places got extremely damaged. And the most beloved place for a man is their place of prayer, their holy places. Something similar happened in Taal Batangas.</p>
                        <p class="text-justify">The FTC “We care we Share” foundation. FTC The Netherlands, under the supervision of Dr. Apostle Edmund Ibanez the founder of the Fundamental Truth of Christ Worldwide Ministry. Apart from praying for the people we are expressing our care through action by charity works. FTC never fails to amaze people by their abilities to serve in the way of God. The FTC foundation always stayed in the first row in helping the needy people and spread joy. Special thanks to the experts of FTC and volunteer-representatives in the Philippines for making FTC proud, prominent and blessed. They aim to make people joyful by making a strong bond of joy, contribution, charity and the taste of the joy of being blessed. FTC will always play a significant role in making society rich in spirit, love, joy and peace and detached from sufferings and starvation. FTC is a hope and a source from God to needy people.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Introduction;