import React from "react";
import TaalVulcano from "./Pandemic2020images/3.jpeg";
import WeCareWeShare from "./Pandemic2020images/7.jpeg";
import TargetedFamilies from "./Pandemic2020images/1.jpeg";
import Covid19 from "./Pandemic2020images/2.jpeg";
import Contribution from "./Pandemic2020images/13.jpeg";
import Covid19Lucban from "./Pandemic2020images/12.jpeg";
import Covid19Lucban2 from "./Pandemic2020images/7.jpeg";
import ParanaqueInFire from "./Paranaque2020images/3.jpeg";

const Pandemic2020 = () => {
    return (
        <div>
            {/* <!-- Pandemic & Natural Disasters 2020 --> */}
            <div class="p-3">
            <h3>Pandemic & Natural Disasters 2020</h3>
            <div class="row row-cols-1 row-cols-md-3 g-4">
                <div class="col">
                <div class="card h-100">
                    <img src={TaalVulcano} class="card-img-top rounded-3" alt="Taal Volcano"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">Taal Vulcano</h5>
                    <p class="card-text">The year 2020 was the time to face a natural disaster. The lava of the mountain entered the roads of Taal and made extreme damage to the houses and cars of the residents. Still, people are not that sad but now it is time to make them sad. The people and the houses were surrounded by lava and got extremely damaged. People are unable to stop this disaster. The only thing they did was to watch with a heavy heart. But it is cleared to all of us that we are unable to control any disaster that came from the nature.</p>
                    </div>
                </div>
                </div>
                <div class="col">
                <div class="card h-100">
                    <img src={WeCareWeShare} class="card-img-top rounded-3" alt="FTCWM"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">FTCWM: We care we share</h5>
                    <p class="card-text">Whenever a disaster occurred, God created some source to help needy people. In this disaster, Fundamental Truth of Christ foundation was one of the sources of God. Due to extreme damage to the villages, people were homeless, helpless, and hungry. FTC with their volunteers decided to help these poor who lost their houses, their food, and their loved ones. Disasters always leave a scar in the society that takes time to heal. FTC targeted 50 families and rescued them by giving them support with relief goods. They rescue them in the sense that they feed them and most importantly they contributed financially from donations to help these victims of the disaster.</p>
                    </div>
                </div>
                </div>
                <div class="col">
                <div class="card h-100">
                    <img src={TargetedFamilies} class="card-img-top rounded-3" alt="Targeted families"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">Targeted families</h5>
                    <p class="card-text">FTC made a rough estimate of helping at least 50 families. The Fundamental Truth of Christ foundation did what they thought, they helped more than 50 families. They are funded by the FTC Netherlands and Germany. The project site was the Taal Batangas evacuation center.</p>
                    </div>
                </div>
                </div>
                <div class="col">
                <div class="card h-100">
                    <img src={Covid19} class="card-img-top rounded-3" alt="Targeted families"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">Covid-19 pandemic and the Philippines</h5>
                    <p class="card-text">A virus named Covid-19 was ruling the year 2020. It locked people in their houses and made a huge decrement in the economy of every country. People were facing unemployment, starvation, and the most important thing their mental condition got extremely damaged. They are unable to meet their loved ones even they could not handshake. We’ve faced very difficult times like world war or events similar to covid-19. But we can say that the COVID-19 time was the worst time of the year. It makes the year 2020 worse for some people. Some people lost their dreams but some lost their lives.</p>
                    </div>
                </div>
                </div>
                <div class="col">
                <div class="card h-100">
                    <img src={ParanaqueInFire} class="card-img-top rounded-3" alt="Targeted families"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">Paranaque in fire</h5>
                    <p class="card-text">Paranaque was not only fighting with the virus but they also struggle to the burning houses of Paranaque. Fortunately, the families are safe and sound but yes! They are homeless now. In these difficult times, they are waiting for the source of God to help them and get them out from this pandemic and disaster. So, for every pandemic and disaster, God created a source of help.</p>
                    </div>
                </div>
                </div>
                <div class="col">
                <div class="card h-100">
                    <img src={Contribution} class="card-img-top rounded-3" alt="Targeted families"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">Contribution of FTC</h5>
                    <p class="card-text">FTC made a strategy on how to help the people. After making dozens of rough sketches, they decided to make a plastic bag containing basic items of food like rice, flour, canned sardines, corn beef, noodles, soup, eggs, etc. they gave these bags to the 50 needy families. The most important and difficult task is to separate the needy people. There are still some people or families who are truly needy and waiting for the act of kindness and humanity. FTC made a proper sketch of those families so that they can help the truly needy people. And that’s something very difficult. Here also, FTC standing voluntarily to help needy people. They aim to give the equipment of their basic needs. They targeted at least 50 families and fulfill their basic needs.</p>
                    </div>
                </div>
                </div>
            </div>
            <div class="row pt-3">
                <div class="col">
                <div class="card h-100">
                    <img src={Covid19Lucban} class="card-img-top rounded-3" alt="Targeted families"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">Covid-19 and Lucban</h5>
                    <p class="card-text">As is discussed above, coronavirus was spreading very rapidly. Not the only Philippines, every corner of the Earth is facing this virus and trying to survive from this deadly pandemic. As the very first precaution was to stay at home and make a complete lockdown. While taking action to the first precaution, people were getting unemployed. There is not only one deadly problem but the second one is more dangerous. The second deadly problem is starvation which was caused by unemployment.</p>
                    </div>
                </div>
                </div>
                <div class="col">
                <div class="card h-100">
                    <img src={Covid19Lucban2} class="card-img-top rounded-3" alt="Targeted families"/>
                    <div class="card-body">
                    <h5 class="card-title fw-bold">What did we do in Lucban?</h5>
                    <p class="card-text">As FTC stands in the first row to help people, once again FTC was the source of God for the help of needy people. We made a strategy and decided to pack a bag of dry fruits so that it can boost immunity and help the people to survive. Our experts recommended this package to make a heavy decrement in the death rate. Moreover, it can help the people which were really in need to eat healthy and good food. And at first, FTC decided to target 75 families. After making the gift bags FTC decided to contribute one bag to one family. The reason for giving only one bag is quantity, FTC filled the bag with dry fruits and basic food items which will be enough for one family. These strategies are made under the supervision of experts and also implemented under expert volunteers.</p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default Pandemic2020;