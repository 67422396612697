// Cookies.js
import { useEffect } from 'react';

const Cookies = () => {
    useEffect(() => {
        // Iubenda Configuration
        window._iub = window._iub || [];
        window._iub.csConfiguration = {
            "askConsentAtCookiePolicyUpdate": true,
            "enableFadp": true,
            "enableLgpd": true,
            "enableUspr": true,
            "fadpApplies": true,
            "floatingPreferencesButtonDisplay": "bottom-right",
            "lang": "en-GB",
            "perPurposeConsent": true,
            "siteId": 3303515,
            "usprApplies": true,
            "whitelabel": false,
            "cookiePolicyId": 36063942,
            "banner": {
                "acceptButtonDisplay": true,
                "closeButtonDisplay": false,
                "customizeButtonDisplay": true,
                "explicitWithdrawal": true,
                "listPurposes": true,
                "position": "float-top-center",
                "rejectButtonDisplay": true
            }
        };

        // Load Iubenda scripts
        const script1 = document.createElement('script');
        script1.src = "https://cs.iubenda.com/autoblocking/3303515.js";
        script1.async = true;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = "https://cdn.iubenda.com/cs/gpp/stub.js";
        script2.async = true;
        document.body.appendChild(script2);

        const script3 = document.createElement('script');
        script3.src = "https://cdn.iubenda.com/cs/iubenda_cs.js";
        script3.charset = "UTF-8";
        script3.async = true;
        document.body.appendChild(script3);

        // Cleanup function to remove scripts on unmount
        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(script3);
        };
    }, []);

    return; // This component does not render anything
};

export default Cookies;